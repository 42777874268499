$j: 8;
$space: 1rem;
@mixin space-md-list {
	@for $i from 0 through $j {
		.mt_md_#{$i} { margin-top: $space * $i !important; }
		.mb_md_#{$i} { margin-bottom: $space * $i !important; }
		.ml_md_#{$i} { margin-left: $space * $i !important; }
		.mr_md_#{$i} { margin-right: $space * $i !important; }
		.pt_md_#{$i} { padding-top: $space * $i !important; }
		.pb_md_#{$i} { padding-bottom: $space * $i !important; }
		.pl_md_#{$i} { padding-left: $space * $i !important; }
		.pr_md_#{$i} { padding-right: $space * $i !important; }
	}
}

@mixin space-sp-list {
	@for $i from 0 through $j {
		.mt_sp_#{$i} { margin-top: $space * $i !important; }
		.mb_sp_#{$i} { margin-bottom: $space * $i !important; }
		.ml_sp_#{$i} { margin-left: $space * $i !important; }
		.mr_sp_#{$i} { margin-right: $space * $i !important; }
		.pt_sp_#{$i} { padding-top: $space * $i !important; }
		.pb_sp_#{$i} { padding-bottom: $space * $i !important; }
		.pl_sp_#{$i} { padding-left: $space * $i !important; }
		.pr_sp_#{$i} { padding-right: $space * $i !important; }
	}
}
  
@mixin x-box-sizing ($type: border-box) {
	-webkit-box-sizing: $type; // Safari <= 5.0, Chrome <= 9.0, iOS Safari 3.2 - 4.3 and Android 2.1 - 3.0
	-moz-box-sizing: $type; // FF 2.0 - 28.0
	box-sizing: $type; // IE 8, Opera 9.5+
}

@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

// Rotate
@mixin rotate ($deg) {
	@include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

@mixin translateY ($y) {
	@include transform(translateY($y));
}

// Skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
	-webkit-transform-origin: $origin;
	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	transform-origin: $origin;
}

@mixin transition($sec, $ease) {
	-webkit-transition: all $sec $ease;
    -moz-transition: all $sec $ease;
    -o-transition: all $sec $ease;
    transition: all $sec $ease;
}

@mixin flexbox {
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

//Using this mixin
%flexbox {
	@include flexbox;
}


@mixin inline-flex {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

%inline-flex {
	@include inline-flex;
}

@mixin flex-wrap($value: nowrap) {
	// No Webkit/FF Box fallback.
	-webkit-flex-wrap: $value;

	@if $value==nowrap {
		-ms-flex-wrap: none;
	}

	@else {
		-ms-flex-wrap: $value;
	}

	flex-wrap: $value;
}

@mixin justify-content($value: flex-start) {
	@if $value==flex-start {
		-webkit-box-pack: start;
		-moz-box-pack: start;
		-ms-flex-pack: start;
	}

	@else if $value==flex-end {
		-webkit-box-pack: end;
		-moz-box-pack: end;
		-ms-flex-pack: end;
	}

	@else if $value==space-between {
		-webkit-box-pack: justify;
		-moz-box-pack: justify;
		-ms-flex-pack: justify;
	}

	@else if $value==space-around {
		-ms-flex-pack: distribute;
	}

	@else {
		-webkit-box-pack: $value;
		-moz-box-pack: $value;
		-ms-flex-pack: $value;
	}

	-webkit-justify-content: $value;
	justify-content: $value;
}


@mixin align-items($value: stretch) {
	@if $value==flex-start {
		-webkit-box-align: start;
		-moz-box-align: start;
		-ms-flex-align: start;
	}

	@else if $value==flex-end {
		-webkit-box-align: end;
		-moz-box-align: end;
		-ms-flex-align: end;
	}

	@else {
		-webkit-box-align: $value;
		-moz-box-align: $value;
		-ms-flex-align: $value;
	}

	-webkit-align-items: $value;
	align-items: $value;
}


@mixin order($int: 0) {
	-webkit-box-ordinal-group: $int + 1;
	-moz-box-ordinal-group: $int + 1;
	-webkit-order: $int;
	-ms-flex-order: $int;
	order: $int;
}

@mixin cfix {
	content: "";
	display: block;
	clear: both;
}
@mixin font-awsome {
	text-rendering: auto;
	font-size: 1rem;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-variant: normal;
}

@mixin dcol-all {
    position: relative;
    margin-right: 4rem;
    min-height: 1px;
    @include x-box-sizing;	
}

%dcol-all {
@include dcol-all;
}

// @mixin dcols($value) {
// 	width: 100%/$value;
// 	max-width: 100% / $value;   
// 	min-width: 100% / $value;
// }
