@use './scss/reset.scss';
@use './scss/grid.scss';

@import 'aos/dist/aos.css';
@import '@splidejs/splide/css';
//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;900&family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&family=Yesteryear&display=swap');

//@import 'slick-carousel/slick/slick.scss';
//@import './scss/common.scss'; 

