@use "sass:math";
@import "variable";
@import "mixin";

@mixin calc($key, $value) {
	#{$key}: -webkit-calc(#{$value});
	#{$key}: -moz-calc(#{$value});
	#{$key}: calc(#{$value});
  
}



$col: 12;

@mixin dcol-sp-list() {
    %mr-0 {
		margin-right: 0;
	}
	@for $i from 1 through $col {
		$space:  (1.5rem * $i) - 1.5rem ;
		$bspace:  math.div(1.5rem * $i - 1.5rem, $i);
		.dcolumns_sp_#{$i} {
			.dcol {
				@include calc("width", "(100% - #{$space})/#{$i}");
				&:nth-child(#{$i}n) {
					@extend %mr-0;
				}
			}

			@for $j from 1 through $col {
				$width: 100% / $col * $j;
				.dcol-sp-#{$j} {
					@include calc("width", "#{$width} - #{$bspace}");
				}
			}

			.ds_col {
				&:nth-child(#{$i}n) {
					@extend %mr-0;
				}
			}
		}
	}
}

@mixin dcol-sp-mr {
	%mr-1_5 {
		margin-right: 1.5rem;
	}
	@for $j from 1 through $col {
		.dcol-sp-#{$j} {
			@extend %mr-1_5;
		}
	}
}

@mixin dcol-sm-list() {
    %mr-sm-0 {
        margin-right: 0 !important;
    }
	@for $i from 1 through $col {
		$space:  (1.5rem * $i) - 1.5rem ;
		$bspace:  math.div(1.5rem * $i - 1.5rem, $i);
		.dcolumns_sm_#{$i} {
			.dcol {
				@include calc("width", "(100% - #{$space})/#{$i}");
				&:nth-child(#{$i}n) {
					@extend %mr-sm-0;
				}
			}

			@for $j from 1 through $col {
				$width: math.div(100%, $col) * $j;
				.dcol-sm-#{$j} {
					@include calc("width", "#{$width} - #{$bspace}");
				}
			}

			.ds_col {
				&:nth-child(#{$i}n) {
					@extend %mr-sm-0;
				}
			}
		}
	}
}

@mixin dcol-md-list() {
    %mr-md-0 {
        margin-right: 0 !important;
    }
	@for $i from 1 through $col {
		$space:  (3rem * $i) - 3rem ;
		$bspace:  math.div(3rem * $i - 3rem, $i);
		.dcolumns_md_#{$i} {
			.dcol {
				@include calc("width", "(100% - #{$space})/#{$i}");
				&:nth-child(#{$i}n) {
					@extend %mr-md-0;
				}
			}

			@for $j from 1 through $col {
				$width: math.div(100%, $col) * $j;
				.dcol-md-#{$j} {
					@include calc("width", "#{$width} - #{$bspace}");
				}
			}

			.ds_col {
				&:nth-child(#{$i}n) {
					@extend %mr-md-0;
				}
			}
		}
	}
}

@mixin dcol-lg-list() {
    %mr-lg-0 {
        margin-right: 0 !important;
    }
	@for $i from 1 through $col {
		$space:  (4rem * $i) - 4rem ;
		$bspace:  math.div(4rem * $i - 4rem, $i);
		.dcolumns_lg_#{$i} {
			.dcol {
				@include calc("width", "(100% - #{$space})/#{$i}");
				&:nth-child(#{$i}n) {
					@extend %mr-lg-0;
				}
			}

			@for $j from 1 through $col {
				$width: math.div(100%, $col) * $j;
				.dcol-lg-#{$j} {
					@include calc("width", "#{$width} - #{$bspace}");
				}
			}

			.ds_col {
				&:nth-child(#{$i}n) {
					@extend %mr-lg-0;
				}
			}
		}
	}
}

@mixin dcol-xl-list() {
    %mr-xl-0 {
        margin-right: 0 !important;
    }
	@for $i from 1 through $col {
		$space:  (4rem * $i) - 4rem ;
		$bspace:  math.div(4rem * $i - 4rem, $i);
		.dcolumns_xl_#{$i} {
			.dcol {
				@include calc("width", "(100% - #{$space})/#{$i}");
				&:nth-child(#{$i}n) {
					@extend %mr-xl-0;
				}
			}

			@for $j from 1 through $col {
				$width: math.div(100%, $col) * $j;
				.dcol-lg-#{$j} {
					@include calc("width", "#{$width} - #{$bspace}");
				}
			}

			.ds_col {
				&:nth-child(#{$i}n) {
					@extend %mr-xl-0;
				}
			}
		}
	}
}


.ds-row {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
}

.dcol {
    position: relative;
	margin: 0 3rem 0 0;
	width: 100%;
}

.ds_col {
	position: relative;
	margin: 0 4rem 0 0;
	width: 100%;
}

//@include dcol-mr();

//@include dcol-list();

@media only screen and (min-width: 992px) {
    @include dcol-lg-list();
	
    
}

@media only screen and (min-width: 1200px) {
    @include dcol-xl-list();
    
}

@media only screen and (min-width: 768px) {
    @include dcol-md-list();
	@include space-md-list();
    
}

@media only screen and (max-width: 767px) {
    .dcol {
		margin-right: 1.5rem;
	}
	.ds_col {
		margin-right: 0;
    }
	@include dcol-sp-mr();
	@include dcol-sp-list();
	@include space-sp-list();
}

@media only screen and (max-width: 576px) {
    @include dcol-sm-list();
    
}


